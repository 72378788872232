/**
 * PrivateRoute
 * Higher Order Component that blocks navigation when the user is not logged in
 * and redirect the user to login page
 *
 * Wrap your protected routes to secure your containers
 */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

export const PrivateRoute = ({ component: Component, componentProps = {}, ...props }) => {
  const { authentication } = useSelector(state => ({ authentication: state.authentication }));
  /**
   * user is authenticated, render component
   */
  if (authentication?.loggedIn === true) {
    return <Route render={() => <Component {...componentProps} />} {...props} />;

    /**
     * user is not authenticated, redirect to login
     */
  } else {
    return (
      <Route path={'/'}>
        <Redirect to={'/login'} />
      </Route>
    );
  }
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired
};
