import axios from 'axios';
import { store } from 'Core/store';
import { userConstants } from '../constants';
import { getFormdata } from '../utils/form_helpers';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

export const userActions = {
  login,
  logout,
  checkSession,
  recover,
  resetAccount
};

function checkSession(history) {
  const data = {
    program: 'User',
    controller: 'XIndex',
    action: 'home'
  };

  store.dispatch(request());

  return axios
    .get(process.env.REACT_APP_API, { params: data })
    .then(loginResult => {
      store.dispatch(success(loginResult.data.result));
      history.push('/');
    })
    .catch(() => {
      store.dispatch(failure());
    });

  function request() {
    return { type: userConstants.REMEMBER_LOGIN_REQUEST };
  }

  function success(user) {
    return { type: userConstants.REMEMBER_LOGIN_SUCCESS, user };
  }

  function failure() {
    return { type: userConstants.REMEMBER_LOGIN_FAILURE };
  }
}

function login(history, data) {
  const { username, password, captcha, remember, password1, password2 } = data;

  store.dispatch(request({ username }));

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'login'
  };

  const fd = new FormData();

  fd.append('username', username);
  fd.append('pw', password);

  if (captcha) {
    fd.append('captcha', captcha);
  }
  if (remember) {
    fd.append('rememberme', remember);
  }
  if (password1 && password2) {
    fd.append('pw1', password1);
    fd.append('pw2', password2);
  }

  //Todo: Handle error response
  return axios
    .post(process.env.REACT_APP_API, fd, { params: pca })
    .then(response => {
      const loginActionResult = response.data.result.program.User.XIndex.login;

      if (loginActionResult.status === true) {
        const navigationActions = response.data.result.toolbox.navi.actions;
        store.dispatch(success({ actions: navigationActions, remembermeStatus: remember }));
        history.push('/');
      } else {
        if (loginActionResult?.data?.captcha) {
          store.dispatch(require_captcha());
        }

        if (loginActionResult?.messages) {
          loginActionResult.messages.forEach(msg => {
            if (msg.message === 'Login failure. Check user/password') {
              throw Error('Anmeldung fehlgeschlagen, bitte überprüfen Sie Nutzernamen und Passwort.');
            } else if (msg.code === 'captcha' && msg.message === 'Invalid captcha') {
              throw Error('Das eingegebene Captcha war ungültig, bitte erneut versuchen.');
            } else if (msg.code === 'error' && msg.message.startsWith('Account expired.')) {
              throw Error('Ihre Zugangsdaten sind abgelaufen, bitte kontaktieren Sie Ihren Administrator.');
            } else if (msg.code === 'pwexpired') {
              store.dispatch(require_pwupdate());
              throw Error('Ihr Passwort ist abgelaufen, bitte vergeben Sie ein neues Passwort:');
            } else {
              throw Error('Es ist ein Fehler aufgetreten, bitte kontaktieren Sie Ihren Administrator.');
            }
          });
        }
      }
    })
    .catch(e => {
      store.dispatch(failure(e.message));
    });

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(payload) {
    return { type: userConstants.LOGIN_SUCCESS, payload };
  }
  function require_captcha() {
    return { type: userConstants.LOGIN_CAPTCHA_REQUIRED };
  }
  function require_pwupdate() {
    return { type: userConstants.LOGIN_PWUPDATE_REQUIRED };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return dispatch => {
    dispatch(request());

    const pca = {
      program: 'User',
      controller: 'XIndex',
      action: 'logout'
    };

    return axios
      .post(process.env.REACT_APP_API, null, { params: pca })
      .then(response => {
        if (response.status === 200 && response.data.result.program.User.XIndex.logout.status === true) {
          dispatch(success());
        } else if (response.status === 200 && response.data.result.program.User.XIndex.logout.status !== true) {
          const messages = response.data.result.program.User.XIndex.logout.messages;
          throw Error('Logout failed with error message(s): ' + messages.map(msg => msg.message).join('\n'));
        } else {
          throw Error(`Request failed with status code ${response.status}`);
        }
      })
      .catch(err => {
        dispatch(failure(err));
      });
  };

  function request() {
    return { type: userConstants.LOGOUT_REQUEST };
  }
  function success(payload) {
    return { type: userConstants.LOGOUT_SUCCESS, payload };
  }
  function failure(error) {
    return { type: userConstants.LOGOUT_FAILURE, error };
  }
}

function recover({ email, captcha }) {
  store.dispatch(request({ email }));

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'recover'
  };

  return axios
    .post(process.env.REACT_APP_API, getFormdata({ username: email, captcha }), { params: pca })
    .then(x => {
      if (x.status !== 200) {
        throw Error(`Request failed with status code ${x.status}`);
      }

      const actionResult = x.data.result.program.User.XIndex.recover;
      if (actionResult.status === true) {
        store.dispatch(success(x.data));
      } else {
        throw Error(actionResult.messages.map(msg => msg.message).join('\n'));
      }
    })
    .catch(err => {
      store.dispatch(failure(err.message));
      throw err;
    });

  function request(user) {
    return { type: userConstants.RECOVER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RECOVER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RECOVER_FAILURE, error };
  }
}

function resetAccount({ resetToken, username, password1, password2, captcha }) {
  const request = payload => ({
    type: userConstants.RESET_LOGIN_REQUEST,
    payload
  });
  const success = payload => ({
    type: userConstants.RESET_LOGIN_SUCCESS,
    payload
  });
  const failure = payload => ({
    type: userConstants.RESET_LOGIN_FAILURE,
    error: payload
  });

  store.dispatch(request({}));

  const pca = {
    program: 'User',
    controller: 'XIndex',
    action: 'resetpassword'
  };

  const data = {
    id: resetToken,
    username,
    pw1: password1,
    pw2: password2,
    captcha
  };

  return axios
    .post(process.env.REACT_APP_API, getFormdata(data), { params: pca })
    .then(x => {
      if (x.status !== 200) {
        throw Error(`Request failed with status code ${x.status}`);
      }

      const actionResult = x.data.result.program.User.XIndex.resetpassword;
      if (actionResult.status === true) {
        store.dispatch(success(x.data));
      } else {
        throw Error(actionResult.messages.map(msg => msg.message).join('\n'));
      }
    })
    .catch(err => {
      store.dispatch(failure(err.message));
      throw err;
    });
}
