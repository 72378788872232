export const userConstants = {
  // REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  // REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  // REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  LOGIN_CAPTCHA_REQUIRED: 'LOGIN_CAPTCHA_REQUIRED',
  LOGIN_PWUPDATE_REQUIRED: 'LOGIN_PWUPDATE_REQUIRED',

  REMEMBER_LOGIN_SUCCESS: 'REMEMBER_LOGIN_SUCCESS',
  REMEMBER_LOGIN_REQUEST: 'REMEMBER_LOGIN_REQUEST',
  REMEMBER_LOGIN_FAILURE: 'REMEMBER_LOGIN_FAILURE',

  RECOVER_REQUEST: 'RECOVER_REQUEST',
  RECOVER_SUCCESS: 'RECOVER_SUCCESS',
  RECOVER_FAILURE: 'RECOVER_FAILURE',

  RESET_LOGIN_REQUEST: 'RESET_LOGIN_REQUEST',
  RESET_LOGIN_SUCCESS: 'RESET_LOGIN_SUCCESS',
  RESET_LOGIN_FAILURE: 'RESET_LOGIN_FAILURE',

  LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

  LOGOUT: 'USERS_LOGOUT'

  // GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  // GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  // GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  // DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  // DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  // DELETE_FAILURE: 'USERS_DELETE_FAILURE'
};
