import React, { useEffect } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { version } from '../package.json';

import { store } from 'Core/store';
import { BaseLayout } from 'Core/pages';
import 'Core/axiosConfig';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'react-toastify/dist/ReactToastify.css';
import 'Core/assets/css/app.css';

global.REACT_APP_VERSION = version;

function App(props) {
  useEffect(() => {
    axios
      .get(`${process.env.PUBLIC_URL}/meta.json?${Date.now()}`)
      .then(({ data }) => {
        if (process.env.NODE_ENV !== 'development') {
          Sentry.init({
            release: `toolbox-frontend@${data.branch}-v${data.version}`,
            dsn: 'https://dded3ff1c0974d1f9a8a68df72d088df@sentry.io/1400368'
          });
        }

        if (data.version !== global.REACT_APP_VERSION) {
          toast('Die Software wurde aktualisiert, bitte laden Sie diese Seite neu und löschen ggf. den Seiten-Cache.');

          console.log(
            `Warning: Frontend was updated on the server. Please reload the page and refresh caches if necessary!\n` +
              `Build-Version: ${data.version}\n` +
              `App-Version: ${version}`
          );

          store.dispatch({
            type: 'FRONTEND_OUTDATED',
            payload: { version: data.version }
          });
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <Provider store={props.store}>
      <PersistGate persistor={store.persistor} loading={<div>...rehydrating</div>}>
        <BaseLayout />
        <ToastContainer position='bottom-center' />
      </PersistGate>
    </Provider>
  );
}

render(<App store={store} />, document.getElementById('root'));
