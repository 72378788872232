import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export const LoginLayout = props => {
  return (
    <>
      <Header />
      <div className='d-flex flex-column flex-fill no-scroll bg-breadcrump' style={{ minHeight: '0' }}>
        {props.children}
      </div>
      <Footer />
    </>
  );
};
