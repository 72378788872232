import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { FormText } from 'reactstrap';
import { toast } from 'react-toastify';

import { LoginLayout } from '../pages';
import { alertActions, userActions } from '../actions';

const styles = {
  container: {
    textAlign: 'center!important'
  },
  form: {
    margin: 'auto',
    width: '100%',
    maxWidth: '330px',
    display: 'block'
  }
};

const INITIAL_STATE = {
  submitted: false,
  username: '',
  password1: '',
  password2: '',
  captcha: ''
};

export const ResetAccount = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const resetToken = props.match.params.hash;
  const authentication = useSelector(state => state.authentication);

  const [formState, setFormState] = useState(INITIAL_STATE);

  const handleFormChange = e => {
    const { name, value } = e.target;

    setFormState(state => ({
      ...state,
      submitted: false,
      [name]: value
    }));
  };

  const submitResetAccount = e => {
    e.preventDefault();

    if (formState.username && formState.password1 && formState.password2 && formState.captcha) {
      userActions
        .resetAccount({ resetToken, ...formState })
        .then(() => {
          toast('Ihr Passwort wurde erfolgreich geändert.');
          history.push('/');
        })
        .catch(() => {
          setFormState(state => ({ ...state, submitted: true }));
        });
    } else {
      dispatch(alertActions.error('Please insert username & password!'));
    }
  };

  return (
    <LoginLayout>
      <div className='container-fluid align-middle d-flex flex-fill bg-breadcrump' style={styles.container}>
        <form style={styles.form} onSubmit={submitResetAccount}>
          <h1 className='h3 mb-3 font-weight-normal text-uppercase'>Reset Account</h1>

          {formState.submitted && authentication.error && <h6 className='text-danger'>{authentication.error}</h6>}

          <div className={'form-group'}>
            <label htmlFor='username' className='form-control-label sr-only'>
              Username
            </label>
            <FormText>Username</FormText>
            <input
              type='text'
              name='username'
              id='username'
              className={'form-control' + (formState.submitted && !formState.username ? ' is-invalid' : '')}
              placeholder='Username'
              autoFocus
              value={formState.username}
              onChange={handleFormChange}
            />
            {formState.submitted && !formState.username && <div className='invalid-feedback'>Username is required</div>}
          </div>

          <div className={'form-group'}>
            <label htmlFor='password1' className='form-control-label sr-only'>
              New Password
            </label>
            <FormText>Enter new Password</FormText>
            <input
              type='password'
              name='password1'
              id='password1'
              className={'form-control' + (formState.submitted && !formState.password1 ? ' is-invalid' : '')}
              placeholder='Password'
              value={formState.password1}
              onChange={handleFormChange}
            />
            {formState.submitted && !formState.password1 && (
              <div className='invalid-feedback'>Password is required</div>
            )}
          </div>

          <div className={'form-group'}>
            <label htmlFor='password2' className='form-control-label sr-only'>
              Repeat new Password
            </label>
            <FormText>Repeat new Password</FormText>
            <input
              type='password'
              name='password2'
              id='password2'
              className={'form-control' + (formState.submitted && !formState.password2 ? ' is-invalid' : '')}
              placeholder='Password'
              value={formState.password2}
              onChange={handleFormChange}
            />
            {formState.submitted && !formState.password2 && (
              <div className='invalid-feedback'>Password is required</div>
            )}
          </div>

          <div className={'form-group form-row'}>
            <div className='col'>
              <img src='/captcha.php' alt='' />
            </div>
            <div className='col'>
              <label htmlFor='inputCaptcha' className='form-control-label sr-only'>
                Captcha
              </label>
              <input
                type='text'
                name='captcha'
                id='inputCaptcha'
                className={'form-control' + (formState.submitted && !formState.captcha ? ' is-invalid' : '')}
                placeholder='enter characters'
                value={formState.captcha}
                onChange={handleFormChange}
              />
              {formState.submitted && !formState.captcha && <div className='invalid-feedback'>Captcha is required</div>}
            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-secondary'
              type='submit'
              disabled={
                formState.submitted ||
                !formState.username ||
                !formState.password1 ||
                !formState.password2 ||
                !formState.captcha
              }
            >
              {authentication.loggingIn ? (
                <>
                  <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true' />
                  {' loading'}
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </LoginLayout>
  );
};
