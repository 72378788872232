import React from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { NavLink } from 'react-router-dom';

import { EditProfileModal } from '.';

const links = (routes = []) =>
  routes.routes
    .filter(item => item.props.visible !== false)
    .map(item => (
      <li key={item.key} className='nav-item'>
        <NavLink
          to={item.props.link ? item.props.link : item.props.path}
          exact={item?.props?.exact || true}
          className='nav-link'
          activeClassName='active'
          isActive={(match, location) => {
            return matchPath(location.pathname, { path: item.props.path, exact: false });
          }}
        >
          {(item.props.name && item.props.name) || (item.key && item.key)}
        </NavLink>
      </li>
    ));

export const Navigation = ({ match, routes, logout }) => {
  const { isEditor, isClientAdmin } = useSelector(state => ({
    isAdmin: !!state.authentication.access?.Admin?.Client?.edit,
    isClientAdmin: !!state.authentication?.access?.DatevEva?.XSurvey?.new,
    isEditor: !!state.authentication?.access?.DatevEva?.XSurvey?.savelist
  }));

  //@Todo: Show only programs relevant to the user (e.g. Administration only for admins/ client moderators)

  let programs = [
    <NavLink to={'/datev'} className='nav-link dropdown-item text-dark' key={'EVA Assistent'}>
      EVA Assistent
    </NavLink>,
    <NavLink to={'/admin'} className='nav-link dropdown-item text-dark' key={'Administration'}>
      Administration
    </NavLink>
  ];

  if (!isClientAdmin) {
    programs = programs.filter(program => program.key !== 'Administration');
  }

  if (!isEditor) {
    routes.routes = routes.routes.filter(route => route.key !== 'WavesComponent');
  }

  const activeProgram = programs.find(program =>
    matchPath(match.path, { path: program.props.to, exact: program.props?.exact || false })
  );

  const otherPrograms = programs.filter(
    program => !matchPath(match.path, { path: program.props.to, exact: program.props?.exact || false })
  );

  return (
    <>
      {/*navbar toggler*/}
      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon' />
      </button>

      {/*dynamic main menu icons*/}
      <div className='collapse navbar-collapse' id='navbarNav'>
        <ul className='navbar-nav mr-auto'>
          {otherPrograms.length > 0 && (
            <li className='nav-item dropdown'>
              <div
                className='nav-link dropdown-toggle pointer'
                href='#'
                id='navbarDropdownMenuLink'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                title={'Change Program'}
              >
                {activeProgram ? activeProgram.props.children : 'select program'}
              </div>
              <div className='dropdown-menu' aria-labelledby='navbarDropdownMenuLink'>
                {otherPrograms}
              </div>
            </li>
          )}

          {/*<NavLink to={'/datev'} exact className="nav-link">EVA Assistent</NavLink>*/}
          {/*<NavLink to={'/admin'} exact className="nav-link">Administration</NavLink>*/}

          {links(routes)}
        </ul>

        <ul className='navbar-nav align-content-end'>
          <li className='nav-link pointer' onClick={() => logout()}>
            Logout
          </li>

          <EditProfileModal />
          {/* <NavLink to={'/profile'} title={'Profil-Einstellungen'}>
            <AccountButton className='btn-outline-light' />
          </NavLink> */}
        </ul>
      </div>
    </>
  );
};
