import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const emoji = useRandomEmojiCycle();

  return (
    <div
      className='d-flex container-fluid w-100 bg-breadcrump justify-content-between align-items-center text-muted'
      style={{ fontSize: '14px', height: '30px' }}
    >
      <div>{`v${global.REACT_APP_VERSION}`}</div>
      <div>
        © 2021 mindline analytics | made with {emoji} in Hamburg, Germany{' | '}
        <Link className={'text-muted'} to={'/imprint'}>
          Impressum
        </Link>
      </div>
    </div>
  );
};

export default Footer;

const useRandomEmojiCycle = () => {
  const getEmoji = useCallback(() => {
    const emojis = ['🤍', '🦄', '🚀', '🍺'];
    return emojis[Math.floor(Math.random() * emojis.length)];
  }, []);
  const [emoji, setEmoji] = useState(getEmoji());

  useEffect(() => {
    const interval = setInterval(() => {
      setEmoji(getEmoji());
    }, 5000);

    return () => clearInterval(interval);
  }, [getEmoji]);

  return emoji;
};
