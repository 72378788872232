import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
// import { createBrowserHistory } from 'history';
import { coreReducers } from './reducers';

// export const history = createBrowserHistory({
//   basename: process.env.PUBLIC_URL
// });

const createRootReducer = (asyncReducers = {}) =>
  combineReducers({
    // router: connectRouter(history),
    ...coreReducers,
    ...asyncReducers
  });

const middleware = [thunkMiddleware];

const composedEnhancer = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(createRootReducer(), composedEnhancer);

// Add a dictionary to keep track of the registered async reducers
store.asyncReducers = {};
store.persistor = persistStore(store);

store.injectReducers = reducers => {
  reducers.forEach(({ name, fn }) => {
    store.asyncReducers[name] = fn;
  });

  // Flush Persists the pending state to avoid the loss of state as
  // replaceReducer causes a rehydrate
  store.persistor.flush();
  store.replaceReducer(createRootReducer(store.asyncReducers));
};
