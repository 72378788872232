import React from 'react';
import { Redirect } from 'react-router';
import { LoginLayout } from '../pages';

const HomeRoute = () => (
  <LoginLayout>
    <Redirect to={`/datev`} />
  </LoginLayout>
);

export default HomeRoute;
