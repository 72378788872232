import { persistReducer } from 'redux-persist';
import local from 'redux-persist/lib/storage';
import { userConstants } from '../constants';

const INITIAL_STATE = {
  remember: false
};

const settingsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case userConstants.LOGIN_SUCCESS:
      return { remember: payload.remembermeStatus };

    case userConstants.LOGOUT_SUCCESS:
      return { remember: false };

    default:
      return state;
  }
};

const persistConfig = {
  key: 'settings',
  storage: local
};

export const settings = persistReducer(persistConfig, settingsReducer);
