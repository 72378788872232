import { Component } from 'react';
import { routeActions } from '../actions/route.actions';
import { store } from '../store';

export default class DynamicImport extends Component {
  state = {
    component: null
  };

  componentDidMount() {
    const { load } = this.props;

    load()
      .then(loadedModule => {
        const { routes, component, reducers, messages } = loadedModule;

        if (reducers) {
          store.injectReducers(reducers);
        }

        if (routes) {
          store.dispatch(routeActions.add(routes));
        }

        if (messages) {
          this.props.mergeMessages(messages);
        }

        this.setState({
          component: component ? component : loadedModule.default
        });
      })
      .catch(error => console.log(error.message));
  }

  render() {
    return this.props.children(this.state.component);
  }
}
