import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

import { AccountButton } from 'Common/components/buttons';
import { profileActions } from 'Core/actions';
import { useSelector } from 'react-redux';
import { FormGroup } from 'reactstrap';
import { Label } from 'reactstrap';
import { Input } from 'reactstrap';
import { Row } from 'reactstrap';
import { Col } from 'reactstrap';
import { Form, FormText } from 'reactstrap';
import { FormFeedback } from 'reactstrap';

export const EditProfileModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [passwordChanges, setPasswordChanges] = useState({
    submitted: false,
    currentPasswort: '',
    newPassword1: '',
    newPassword2: ''
  });

  const { profile } = useSelector(state => ({ profile: state.profile }));

  useEffect(() => {
    if (!profile?.firstname && !profile.loading) profileActions.edit();
    // else {
    //   setProfile({
    //     email: profile?.email?.value || '',
    //     username: profile?.username?.value || '',
    //     firstname: profile?.firstname?.value || '',
    //     lastname: profile?.lastname?.value || ''
    //   });
    // }
  }, [profile]);

  const toggleModal = useCallback(() => setIsOpen(b => !b), []);
  
  const handleChange = useCallback(({ target }) => {
    const { name, value } = target;
    setPasswordChanges(s => ({ ...s, [name]: value, submitted: false }));
  }, []);

  const handleSubmit = useCallback(() => {
    setPasswordChanges(s => ({ ...s, submitted: true }));
    profileActions
      .changepassword(passwordChanges.currentPasswort, passwordChanges.newPassword1, passwordChanges.newPassword2)
      .then(toggleModal());
    return;
  }, [passwordChanges, toggleModal]);

  return (
    <>
      <AccountButton className={'btn-outline-light'} onClick={toggleModal} />
      <Modal isOpen={isOpen} toggle={toggleModal} size={'xl'} centered>
        <ModalHeader>Änderung Ihres Passworts</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              Um das Passwort Ihres Zugangs zu ändern, geben Sie bitte Ihr aktuelles Passwort sowie zweifach das
              gewünschte neue Passwort ein.
            </Col>
          </Row>

          <hr />
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for='currentPasswort'>Aktuelles Passwort</Label>
                  <Input
                    id='currentPasswort'
                    type='password'
                    name='currentPasswort'
                    placeholder='...'
                    autoComplete={'current-password'}
                    value={passwordChanges.currentPasswort}
                    onChange={handleChange}
                    invalid={passwordChanges.submitted && !!profile?.error?.pw}
                  />
                  <FormFeedback>{!!profile?.error?.pw && `Ihr aktuelles Passwort ist nicht korrekt.`}</FormFeedback>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for='newPassword1'>Neues Passwort:</Label>
                  <Input
                    id='newPassword1'
                    type='password'
                    name='newPassword1'
                    placeholder='...'
                    autoComplete={'new-password-1'}
                    value={passwordChanges.newPassword1}
                    onChange={handleChange}
                    invalid={passwordChanges.submitted && !!profile?.error?.pw1}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col />
              <Col>
                <FormGroup>
                  <Label for='newPassword2'>Wiederholung des neuen Passworts:</Label>
                  <Input
                    id='newPassword2'
                    type='password'
                    name='newPassword2'
                    autoComplete={'new-password-2'}
                    placeholder='...'
                    value={passwordChanges.newPassword2}
                    onChange={handleChange}
                    invalid={passwordChanges.submitted && !!profile?.error?.pw1}
                  />
                  <FormText>
                    {`Mindestens 8 Zeichen, je mindestens eine Zahl, Buchstabe und Sonderzeichen`}
                    Verfügbare Sonderzeichen: <i>{`! " # $ % & ' * + , . / : ; = ? @ ^ _ \` | ~ - ( { [ < > ] } )`}</i>
                  </FormText>
                  <FormFeedback>{`Das neue Passwort entspricht nicht den Anforderungen oder stimmt nicht mit der Wiederholung überein.`}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Form>

          {/* <FormGroupInputText
            label={'Aktuelles Passwort:'}
            name={'password_old'}
            autocomplete={'current-password'}
            type={'password'}
            placeholder={'aktuelles Passwort'}
            value={pw}
            onChangeFn={e => handleChange(e, setPw)}
            submitted={submitted}
            invalidText={
              props.error &&
              props.error.pw &&
              Object.keys(props.error.pw).map(key => (
                <React.Fragment key={key}>
                  <span>{props.error.pw[key]}</span>
                  <br />
                </React.Fragment>
              ))
            }
          /> */}

          {/* <FormGroupInputText
            label={'Neues Passwort:'}
            name={'password_new1'}
            autocomplete={'new-password'}
            type={'password'}
            placeholder={'neues Passwort'}
            value={pwn1}
            onChangeFn={e => handleChange(e, setPwn1)}
            submitted={submitted}
            invalidText={
              props.error &&
              props.error.pw1 &&
              Object.keys(props.error.pw1).map(key => (
                <React.Fragment key={key}>
                  <span>{props.error.pw1[key]}</span>
                  <br />
                </React.Fragment>
              ))
            }
          /> */}

          {/* <FormGroupInputText
            label={'Wiederholung neues Passwort:'}
            name={'password_new2'}
            autocomplete={'new-password'}
            type={'password'}
            placeholder={'Wiederholung neues Passwort'}
            value={pwn2}
            onChangeFn={e => handleChange(e, setPwn2)}
            submitted={submitted}
            invalidText={
              props.error &&
              props.error.pw2 &&
              Object.keys(props.error.pw2).map(key => (
                <React.Fragment key={key}>
                  <span>{props.error.pw2[key]}</span>
                  <br />
                </React.Fragment>
              ))
            }
          /> */}

          {/* {props.messages && (
            <FormGroup>
              <h6 className={'text-success mt-3'}>{props.messages.map(msg => msg.message).join('<br />')}</h6>
            </FormGroup>
          )} */}
        </ModalBody>
        <ModalFooter>
          <Button color={null} className='btn-outline-danger' onClick={toggleModal}>
            schließen
          </Button>
          <Button
            color={null}
            className='btn-outline-success'
            disabled={
              passwordChanges.currentPasswort.length <= 0 ||
              passwordChanges.newPassword1.length <= 0 ||
              passwordChanges.newPassword2.length <= 0 ||
              passwordChanges.newPassword1 !== passwordChanges.newPassword2
            }
            onClick={handleSubmit}
          >
            speichern
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
