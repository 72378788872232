import axios from 'axios';
import { isEqual } from 'lodash';
import { 
  // history, 
  store } from './store';

const checkSessionParams = {
  action: 'home',
  controller: 'XIndex',
  program: 'User'
};

axios.defaults.withCredentials = true;
axios.defaults.timeout = 30000;

axios.interceptors.response.use(
  response => response,
  error => {
    // Do something with response error
    const { status } = error.response;

    if (status === 401 && !isEqual(error.response.config.params, checkSessionParams)) {
      //@Todo: Check if just an unauthorised method or session expired by calling User/Home/index?
      store.dispatch({ type: 'USER_SESSION_EXPIRED' });
      // history.push('/login');
    }

    return Promise.reject(error);
  }
);
