/**
 * Reads all selected option values from an select HTML DOM element and returns them as an array.
 *
 * @param select HTML select DOM element
 * @returns {Array} array of selected values
 */
export function getMultiSelectValues(select) {
  const result = [];
  const options = select && select.options;
  let opt;

  for (let i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }

  return result;
}

/**
 * Creates a new FormData object and appends all data key/ value pairs to the created object.
 * Non string values will be converted via JSON.stringify before beeing appended - as long as the stringify flag is
 * not false.
 *
 * @param data (object) Object with data that should be appended to the FormData object.
 * @param stringify (boolean) Flag that defines if non-string values should be stringify'd.
 *
 * @returns {FormData}
 */
export const getFormdata = (data, stringify = true) => {
  const formData = new FormData();

  for (const key in data) {
    if (typeof data[key] !== 'string' && stringify === true) {
      data[key] = JSON.stringify(data[key]);
    }

    formData.append(key, data[key]);
  }

  return formData;
};
