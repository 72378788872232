import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import * as routes from 'Core/routes';
import { PrivateRoute, DynamicImport } from 'Core/containers';

const Datev = props => (
  <DynamicImport load={() => import(/* webpackChunkName: "datev" */ 'Modules/datev_eva/')}>
    {Component => (Component === null ? <div>loading...</div> : <Component {...props} />)}
  </DynamicImport>
);

const Admin = props => (
  <DynamicImport load={() => import(/* webpackChunkName: "admin" */ 'Modules/admin')}>
    {Component => (Component === null ? <div>loading...</div> : <Component {...props} />)}
  </DynamicImport>
);

export const BaseLayout = () => {
  return (
    <Router basename={process.env?.PUBLIC_URL || ''}>
      <Switch>
        <Route path={`/login`} component={routes.Login} exact />
        <Route path={`/reset-account/:hash`} component={routes.ResetAccount} exact />

        <PrivateRoute path={`/`} component={routes.Home} exact />
        <PrivateRoute path={'/profile'} component={routes.ProfileRoute} exact />
        <PrivateRoute path={`/admin`} component={Admin} />
        <PrivateRoute path={`/datev/:eva?/:area?/:surveyid?`} component={Datev} />
        <Route path={`/imprint`} component={routes.Imprint} exact />
      </Switch>
    </Router>
  );
};
